// import '../styles/App.css';
// import '../styles/NavBar.css';

export default function NavBarLink(props) {
  return (
    <div
      className={`nav-link ${
        window.location.pathname === props.goto ? 'text-white' : ''
      }`}
      onClick={() => {
        window.location.href = props.goto;
      }}
    >
      <div className="d-flex align-items-center justify-content-between">
        <div className="nav-link-icon">{props.children}</div>
        <div className="nav-link-text">{props.text}</div>
      </div>
    </div>
  );
}
