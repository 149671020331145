// import '../styles/App.css';
import '../styles/Resume.css';
import Layout from '../templates/Layout';
import { useState } from 'react';
import ResumeFolder from './partials/ResumeFolder';
import { FaArrowAltCircleDown } from 'react-icons/fa';

export default function Resume(props) {
  const professionalExperience = [
    {
      title: 'Product Owner and Software Engineer at TERMA (Space)',
      period: 'SEP 2022 - PRESENT (Leiden, The Netherlands)',
      list: ["Initially worked on the Mission Planning System “PLAN” as a Software Engineer, until taking on the role of Product Owner.", "Main responsibilities: requirements definition, prioritization and technical implementation, release planning, validation of deliverables, CI/CD maintenance and documentation. ", "Full-stack development, in coordination with other product teams within the TERMA Ground Segment Suite (TGSS).", "Main technologies: Java, Spring Boot, Eclipse RCP, Python, PostgreSQL, Docker, NATS"],
    }, , {
      title: 'Internship at Petapilot (Sovos)',
      period: 'FEB. 2021 - SEP. 2021 (Porto, Portugal)',
      list: ['Database optimization and full-stack development of a web app for data management in the context of tax compliance verification',
      "Main technologies: GraphQL, Node.js, React, PostgreSQL, Docker, Redis"
      ],
    },
  ];
  const certifications = [
    {
      title: 'Complete Tensorflow 2 and Keras Deep Learning Bootcamp',
      period: 'FEB. 2024 (Udemy)',
      list: [
        'A certified weeklong course which covered subjects such as spacecraft design, orbital mechanics, and mission analysis and planning.',
      ],
    },
    {
      title: 'Space Systems Engineering',
      period: 'JUL. 2023 (University of Southampton, UK)',
      list: [
        'A certified weeklong course which covered subjects such as spacecraft design, orbital mechanics, and mission analysis and planning.',
      ],
    },
    {
      title: 'Angular Workshop by EDIT-Disruptive Digital Education',
      period: 'APR. 2021 (Porto, Portugal)',
      list: [],
    },
    {
      title: 'Essential Project Management for Students (taught by APGEI)',
      period: 'APR. 2021 (Porto, Portugal)',
      list: [
        'Introduction to concepts and processes of project mangement according to the PMBOK',
        'Final score 20 (out of 20)',
      ],
    }
  ];
  const academic = [
    {
      title: 'Authored Paper on Deep Reinforcement Learning',
      period: 'OCT. 2022 (Porto, Portugal)',
      list: [
        "Published and presented a paper on Multi-Agent Deep Reinforcement Learning to the ESM®'2022 (The 36th annual European Simulation and Modelling Conference)",
        'The paper summarized the findings of my thesis "Deep Reinforcement Learning for Earth Observation Satellite Constellations"',
      ],
    },
    {
      title: 'Erasmus+ Programme',
      period: 'SEP. 2021 - FEB. 2022 (Ostrava, Czechia)',
      list: [
        'Semester abroad at the Technical University of Ostrava (VSB-TUO)',
        'Studied subjects: Data Science, Digital Image Processing, Biologically Inspired Algorithms, Methods of Analysis of Textual Data', 'Systems and Technology for e-Health',
      ],
    },
    {
      title: 'Master’s in Informatics and Computing Engineering Student at the University of Porto (FEUP)',
      period: 'SEP. 2017 - JUL. 2022 (Porto, Portugal)',
      list: [
        'Final score 18 (out of 20)',
      ],
    },
    {
      title: 'Escola Secundária Garcia de Orta (High School)',
      period: 'SEPT. 2014 - JUN. 2017',
      list: [
        'Science and Technology Course',
        'Final grade of 19 values (out of 20)',
      ],
    },
  ];
  const extraCurricular = [
    {
      title: "EU Youth Digital Hackathon - Solve the Gap",
      period: 'MAR. 2021 (Porto, Portugal)',
      list: ['Mobile app prototype to mitigate youth absentee votes', 'Finalist Team'],
    },
    {
      title: "Eurekathon 2020 - Challenging data for Zero Hunger",
      period: 'NOV. 2020 (Porto, Portugal)',
      list: ["Developed a Machine Learning model to classify aid requests, aiming to improve Portugal's Food Bank response time","Finalist Team"],
    },
    {
      title: "JuniFEUP (Faculty's Junior Enterprise)",
      period: 'SEPT. 2019 - JUL. 2022 (Porto, Portugal)',
      list: ['Technology Department (Full-stack Software Developer)', 'Executive Board Member', 'Secretary of the Supervisory Board',
      "Main technologies: Node.js, React, React Native, PostgreSQL, Laravel, PHP, MongoDB, Firebase, Vagrant"
      ],
    },
    {
      title: 'FEUP BUDDY',
      period: 'SEPT. 2020 - JUN. 2021 (Porto, Portugal)',
      list: [
        'The FEUP Buddy system welcomes the Erasmus+ exchange students to Porto, Portugal. All Buddies do their best to help, so the exchange students can adapt to and succeed in their new home!',
      ],
    },
    {
      title: 'MIEIC.OnBoard FEUP',
      period: 'SEPT. 2019 - JUN. 2020 (Porto, Portugal)',
      list: [
        'MIEIC.OnBoard is an initiative that aims to help freshmen students ajust to their new life in college.',
      ],
    },
    {
      title: 'Associação Senhores Bichinhos (Animal Shelter)',
      period: 'SEPT. 2018 - MAR. 2020 (Porto, Portugal)',
      list: ['Animal care', 'Marketing Communication Manager'],
    },
    {
      title: 'Boys Scouts',
      period: 'SEPT. 2006 - 2016 (Portugal)',
      list: [],
    },
  ];

  return (
    <Layout>
      <div id="resume" className="container">
        <div className="section-title">
          <h1>Resume</h1>
          <span className="subtitle regular-text">
            Projects and events that helped me develop my skills! In this
            section, you can check a history of achievements I'm proud of, as
            well the languages, frameworks and tools I am most comfortable with!
          </span>
          <div>
            <span className="subtitle regular-text align-baseline">
              You can also download my CV{' '}
              <a
                href="João Henrique Luz-EN.pdf"
                className="subtitle align-baseline text-decoration-none"
              >
                <span className="text-monospace"> here</span> <FaArrowAltCircleDown />
              </a>
            </span>
          </div>

          <hr></hr>
        </div>
        {/* <div className="section-header-img-div resume-section-header-img"></div> */}

        <div className="w-100 mx-0 mt-5">
          <div id="all-folders">
            <ResumeFolder title="PROFESSIONAL&nbsp;EXPERIENCE" list={professionalExperience} />
            <ResumeFolder title="ACADEMIC&nbsp;ACHIEVEMENTS" list={academic} />
            <ResumeFolder title="CERTIFICATIONS" list={certifications} />
            <ResumeFolder
              title="EXTRACURRICULAR&nbsp;ACTIVITIES"
              list={extraCurricular}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
