// import '../styles/App.css';
// import '../styles/NavBar.css';
import { FaGithub, FaGitlab, FaLinkedinIn, FaRegEnvelope   } from 'react-icons/fa';
import { FcBusinessman, FcOpenedFolder } from "react-icons/fc";
import { AiOutlineCode } from 'react-icons/ai';
import { BiCoffeeTogo,BiMessageRoundedDots } from 'react-icons/bi';
import { BsTerminal,BsAward, BsChatDots } from 'react-icons/bs';
import { IconContext } from 'react-icons';

import NavBarLink from './NavBarLink';

export default function Navbar(props) {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  };
  return (
    <div className="sidebar">
      <div className="sidebar-column">
        <div className="sidebar-author-section mb-4">
          <h1 className="text-left text-white">João Luz</h1>
          <h5 className="text-left sidebar-subtitle">
            Software Engineer
          </h5>
        </div>
        <div className="text-left ">
          <NavBarLink text="About me" goto="/about-me">
            <BiCoffeeTogo/>
          </NavBarLink>
          <NavBarLink text="Portfolio" goto="/portfolio">
          <BsTerminal/>
          </NavBarLink>
          <NavBarLink text="Resume" goto="/resume">
            <BsAward/>
          </NavBarLink>
          <NavBarLink text="Contact Me" goto="/contact-me">
            <BsChatDots/>
          </NavBarLink>
        </div>
        <div className="d-flex flex-row my-5 social-media-icons">
          <IconContext.Provider
            value={{ style: { fontSize: '30px', color: 'rgb(255, 255, 255)' } }}
          >
            <div
              className="mx-2 social-media-icons-div has-tooltip"
              onClick={() => {
                openInNewTab('https://www.linkedin.com/in/jhreguengo/');
              }}
            >
              <FaLinkedinIn />
              <span className="tooltip-text">
                Connect with me on LinkedIn
              </span>
            </div>
            <div
              className="mx-2 social-media-icons-div has-tooltip"
              onClick={() => {
                openInNewTab('mailto:jhreguengo@gmail.com');
              }}
            >
              <FaRegEnvelope   />
              <span className="tooltip-text">
                Get in touch by email
              </span>
            </div>
            {/* <div
              className="mx-2 social-media-icons-div has-tooltip"
              onClick={() => {
                openInNewTab('https://github.com/joaohenriqueluz');
              }}
            >
              <FaGithub />
              <span className="tooltip-text">
                Check out my repositories on Github
              </span>
            </div> */}
            {/* <div
              className="mx-2 social-media-icons-div has-tooltip"
              onClick={() => {
                openInNewTab('https://gitlab.com/joaohenriqueluz');
              }}
            >
              <FaGitlab />
              <span className="tooltip-text">
                Check out my repositories on Gitlab
              </span>
            </div> */}
          </IconContext.Provider>
        </div>
      </div>
    </div>
  );
}
