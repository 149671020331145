import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import './styles/App.css';
import './styles/Home.css';

import AboutMe from './components/AboutMe.js';
import Portfolio from './components/Portfolio.js';
import Resume from './components/Resume.js';
import ContactMe from './components/ContactMe.js';
import NotFound from './components/NotFound.js';
import Post from './components/Post';

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/about-me" component={AboutMe} />
          <Route exact path="/portfolio" component={Portfolio} />
          <Route exact path="/resume" component={Resume} />
          <Route exact path="/contact-me" component={ContactMe} />
          <Route exact path="/post/:post_id" component={Post} />
          <Route exact path="/">
            <Redirect to="/about-me"/>
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
