import React, { useEffect, useState } from 'react';

// import '../styles/App.css';
// import '../styles/Home.css';

import Navbar from '../components/Navbar';

function Layout(props) {
  const [progressWidth, setProgressWidth] = useState(0);
  const [copySuccess, setCopySuccess] = useState(
    'Click to copy my e-mail to your clipboard!'
  );

  function getYear() { // birthday is a date

    return new Date(Date.now()).getUTCFullYear();
  }

  const year = getYear()

  const setProgress = () => {
    const scrollTotal = document.documentElement.scrollTop;
    const heightWin =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scroll = `${((Math.floor(scrollTotal) - 1) / heightWin) * 100}%`;
    console.log("scrollTotal", scrollTotal)
    console.log("heightWin", heightWin)
    setProgressWidth(scroll);
  };

  function copyToClipboard(e) {
    var copyText = document.getElementById('myInput');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
    setCopySuccess('Great! Now you have my e-mail!');
  }

  useEffect(() => {
    window.onscroll = () => {
      setProgress();
    };
  });

  return (
    <>
      <Navbar />
      <div className="w-100">
        <div className="page-content">
          <div id="progress-container">
            <div id="progress-bar" style={{ width: progressWidth }}></div>
          </div>
          <div className="page-content-children">
            {props.children}{' '}

            <div className='d-flex flex-row justify-content-between w-100'>
              <div style={{ marginTop: '100px' }} className="mt-5 pb-0 mb-0 w-50">
                {document.queryCommandSupported('copy') && (
                  <span>
                    © {year}{' '}
                    <span onClick={copyToClipboard} className="has-tooltip">
                      João Henrique Luz{' '}
                      <span className="tooltip-text">{copySuccess}</span>
                    </span>
                  </span>
                )}
                <input
                  type="text"
                  className="offscreen"
                  value="jhreguengo@gmail.com"
                  id="myInput"
                />
              </div>
              <div style={{ marginTop: '100px' }} className="mt-5 pb-0 mb-0 w-50 subtitle text-right">
                Icons provided by <a href="https://fontawesome.com/ " target="_blank">Font Awesome</a> <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank">(link to License)</a></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Layout;
