// import '../styles/App.css';
// import '../styles/NavBar.css';
import lbaw from '../assets/lbaw.png';
import ProjectCard from './partials/ProjectCard.js';
import Layout from '../templates/Layout';
import {firestore} from '../firebase.js';
import {useState, useEffect} from 'react';

export default function Portfolio(props) {
  const [projects, setProjects] = useState([]);
  // const projects = [
  //   {
  //     name: 'PearToPear',
  //     description: 'A Collaborative News Webapp',
  //     image: lbaw,
  //     tags: ["Web","Laravel","FullstackFullstackFullstack"]
  //   },
  //   {
  //     name: 'PearToPear',
  //     description: 'A Collaborative News Webapp',
  //     image: lbaw,
  //     tags: ["Web","Laravel","Fullstack"]
  //   },
  //   {
  //     name: 'PearToPear',
  //     description: 'A Collaborative News Webapp',
  //     image: lbaw,
  //     tags: ["Web","Laravel","Fullstack"]
  //   },
  //   {
  //     name: 'PearToPear',
  //     description: 'A Collaborative News Webapp',
  //     image: lbaw,
  //     tags: ["Web","Laravel","Fullstack"]
  //   },
  // ];

  useEffect(() => {
    firestore
      .collection('Post')
      .get()
      .then((querySnapshot) => {
        const posts = [];
        querySnapshot.forEach((doc) => {
          const post = doc.data();
          post.id = doc.id;
          posts.push(post);
        });
        setProjects(posts);
        console.log(projects)
      });
    // let requeststr = `${process.env.REACT_APyP_SERVER_URL}/table/${process.env.REACT_APP_PORTFOLIO_INDEX_TABLE}`;
    //     console.log(requeststr);
    //     fetch(requeststr)
    //       .then(res => res.json())
    //       .then(
    //         (result) => {
    //           console.log(result);
    //           setProjects(result);
    //         },
    //         (error) => {
    //           console.log(error);
    //         }
    //       )
  }, []);

  return (
    <Layout>
      <div id="portfolio" className="container">
        <div className="section-title">
          <h1>Portfolio</h1>
          <span className="subtitle regular-text">
            Some of the projects I've developed or contributed to
          </span>
          <hr className=""></hr>
        </div>
        {projects.map((project) => {
          console.log(project)
          return (
              <ProjectCard
              key={"pc"+project.id}
              id={project.id}
              src={project.src}
              name={project.Name}
              description={project.description}
              image={project.preview_img}
              tags={project.tags}
            />
          );
        })}
      </div>
    </Layout>
  );
}
