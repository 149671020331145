import { useState, useEffect } from 'react';

import '../../styles/App.css';
import '../../styles/NavBar.css';
import ResumeAchievements from './ResumeAchievements';

export default function ResumeFolder(props) {
  const [title, setTitle] = useState('');
  const [list, setList] = useState([]);
  const [openedFolder, setOpenedFolder] = useState(false);

  useEffect(() => {
    setTitle(props.title);
    setList(props.list);
  }, [props]);

  return (
    <div className="mb-3">
      <div className="folder-header d-flex flex-row justify-content-between align-items-center">
        <span>{title}</span>
      </div>
      <div className="folder-body">
        {list.map((achivement, index) => (
          <ResumeAchievements
            key={index+achivement.title}
            title={achivement.title}
            period={achivement.period}
            year={achivement.year}
            list={achivement.list}
          />
        ))}
      </div>
    </div>
  );
}
