// import '../styles/App.css';
// import '../styles/AboutMe.css';
import Layout from '../templates/Layout';
import ProjectCard from './partials/ProjectCard';

export default function AboutMe(props) {
  return (
    <Layout>
      <ProjectCard
        name="&lt; Error 404 /&gt;"
        description="Ups! I think you're lost"
        image="https://images.pexels.com/photos/691637/pexels-photo-691637.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
        tags={[]}
      />
    </Layout>
  );
}
