import '../styles/App.css';
// import '../styles/AboutMe.css';
import Layout from '../templates/Layout';
import ReactMarkdown from 'react-markdown';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { firestore } from '../firebase.js';
import { NotionRenderer } from "react-notion";

// import response from "https://notion-api.splitbee.io/v1/page/b34a87212981440fa8e87c80f0949519";

export default function Post(props) {
  const [post, setPost] = useState({ content: "" });
  const [postInfo, setPostInfo] = useState({});
  const [errorLoading, setErrorLoading] = useState(false);
  const { post_id } = useParams();
  const [readingTime, setReadingTime] = useState('');

  // const notion = new NotionAPI()
  // document.title = (post != undefined ? post.name : "Post");

  useEffect(() => {
    firestore
      .collection('Post')
      .doc(post_id)
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          setPostInfo(doc.data())
          document.title=doc.data().name
          // const wordCount = doc.data().content.split(' ').length;
          // setReadingTime((Math.ceil(wordCount / 200) + '').split('.')[0]);
        } else {
          window.location.href = '/404';
        }
        // const recordMap = await notion.getPage(props.src)
        let requeststr = `https://notion-api.splitbee.io/v1/page/${doc.data().src}`//`${process.env.REACT_APP_SERVER_URL}/post/${post_id}`;
        fetch(requeststr)
          .then(res => res.json())
          .then(
            (result) => {
              setPost(result);
            },
            (error) => {
              setErrorLoading(true)
            }
          )

      });
  }, [post_id]);


  return (
    <Layout>
      <div className="notion-container">
        {/* <ReactMarkdown>{post.content.replaceAll('\\n', '\n')}</ReactMarkdown>
         */}
        {errorLoading == false ? <NotionRenderer blockMap={post} />: 
        <>
          <h2>{errorLoading} An error has occured while loading this post.</h2>
          <h3>You can still view it <a href={`https://jhreguengo.notion.site/${postInfo.src}`}>here!</a></h3>
        </>
        }
      </div>
      {/* <a className="mt-5" href='/portfolio'>
        back to portfolio
      </a> */}
    </Layout>
  );
}
