import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyB0PcmUNnfYYcNmtgDLnTMLbdtR4OU1v4Q",
    authDomain: "joaoluz-ebfa2.firebaseapp.com",
    projectId: "joaoluz-ebfa2",
    storageBucket: "joaoluz-ebfa2.appspot.com",
    messagingSenderId: "1056030193303",
    appId: "1:1056030193303:web:fa2844f34e87b6ccfcdbd2",
    measurementId: "G-2N193X8RKD"
};

firebase.default.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();
export const storage = firebase.storage();
