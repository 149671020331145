// import '../styles/App.css';
// import '../styles/AboutMe.css';
import Layout from '../templates/Layout';

export default function AboutMe(props) {
  const hardSkills = [
    'C++',
    'C',
    'Java',
    'Python',
    'Postgres',
    'Prolog',
    'PHP',
    'Laravel',
    'React',
    'Mongo DB',
    'Node.js',
    'JavaScript',
    'Laravel',
    'HTML',
    'CSS',
    'Bootsstrap',
    'React Native',
  ];

  function calculateAge(birthday) { // birthday is a date
    var ageDifMs = Date.now() - birthday;
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
  const birthday = new Date('June 17, 1999 00:00:00');

  const yearsOld = calculateAge(birthday)
  return (
    <Layout>
      <div id="about-me" className="container">
        <div className="section-title">
          <h1>About Me</h1>
          <span className="subtitle regular-text">
            Who I am and what I've been up to lately
          </span>
          <hr></hr>
        </div>
        <div className='mt-5'>
        <div className=" about-me-section-header-img"></div>
        {/* <div className="section-header-img-div about-me-section-header-img"></div> */}
        <div className=" mt-0 w-100 mx-auto">
          <div className="mt-0">
            <h2>Hello, I'm João!✋</h2>
            <div className="mt-3 paragraph regular-text">
            Hi there! I'm a passionate Software Engineer who loves learning new things, especially in the fields of AI and web development.
            <br></br>            
            <br></br>            
            I'm always eager to expand my skills and knowledge, and to collaborate with other talented and creative people.

If you are looking for someone who can bring value, enthusiasm and creativity to your team, I might be the right fit for you. I'm open to exploring new opportunities and challenges in the software industry, and I would love to hear from you if you have a specific position or job offer that matches my profile.

      In the meantime, feel free to check out some of my projects in the portfolio page, where you can see some of the things I've built and learned. 
      <br></br>
You can also leave me a message in the contact page if you have any questions or feedback for me. Don't be shy 😉!
            </div>


            <hr></hr>
          </div>
        </div>
        </div>
      </div>
    </Layout>
  );
}
