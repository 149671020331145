import { useState, useEffect } from "react";
import '../../styles/App.css';
import '../../styles/NavBar.css';

export default function ResumeAchievements(props) {
  const [info, setInfo] = useState({ title: "", period: "", year:"", list: [] });

  useEffect(() => {
    setInfo({ title: props.title, period: props.period, year:props.year, list: props.list });
  }, []);
  return (
    <div className="folder-section">
      <div className="col-1 resume-timeline-div">
        <div className="resume-timeline">
          <span className="dot"></span>
          <div className="date">
            <span>{info.year}</span>
          </div>
        </div>
      </div>
      <div className="col-11">
        <h5 className="folder-section-title">{info.title}</h5>
        <span className="subtitle">{info.period}</span>
        <ul className="pl-3 mt-1">
          {info.list.map((bulletpoint) => (
            <li>{bulletpoint}</li>
          ))}
        </ul>
      </div>
      <hr></hr>
    </div>
  );
}
