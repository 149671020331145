import '../../styles/App.css';
import '../../styles/NavBar.css';

export default function ProjectCard(props) {
  return (
    <div    className="project-card">
      <a target="_blank" rel="noopener noreferrer" href={'/post/' + props.id}>
        
          <div className="project-card-content">
            <img className="project-card-img" alt="screenshot of project UI" src={props.image} />
            <div className="project-card-text">
              <div className="centered-text">
                <h1 className="project-card-title">{props.name}</h1>
                <span className="text-white regular-text">{props.description}</span>
                <div className="d-flex flex-row w-100 justify-content-around">
                  {props.tags.map((value) => (
              <span className="text-danger mx-1">{value}</span>
            ))}
                </div>
              </div>
            </div>
          </div>
      </a>
    </div>
  );
}
