// import '../styles/App.css';
// import '../styles/NavBar.css';
import Layout from '../templates/Layout';
import emailjs, { init } from 'emailjs-com';
import React, { useEffect, useState } from 'react';

export default function ContactMe(props) {
  const [showAlert, setShowAlert] = useState(false);
  const [successAlert, setSuccessAlertAlert] = useState(false);
  const [formDisabled, setFormDisabledAlert] = useState(false);
  const [formData, setFormData] = useState();

  const updateFormData = (property, value)=>{
    let tmp = formData;
    tmp[property]= value;
    setFormData(tmp);
  }

  const emailJS_config = {
    service_id: process.env.REACT_APP_EMAILJS_SERVICE,
    template_id: process.env.REACT_APP_EMAILJS_TEMPLATE,
  };

  function sendEmail(e) {
    e.preventDefault();
    console.log(e.target);
    setSuccessAlertAlert(true);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 10000);
    emailjs
      .sendForm(emailJS_config.service_id, emailJS_config.template_id, e.target)
      .then(
        (result) => {
          console.log(result);
          setSuccessAlertAlert(true);
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 10000);
        },
        (error) => {
          console.log(error);
          setSuccessAlertAlert(false);
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 10000);
        }
      );
    setFormDisabledAlert(true);
    setTimeout(() => {
      setFormDisabledAlert(false);
    }, 10000);
  }

  useEffect(() => {
    init(process.env.REACT_APP_EMAILJS_USER);
  }, []);

  return (
    <Layout>
      <div id="contact-me" className="container">
        <div className="section-title">
          <h1>Contact Me</h1>
          <span className="subtitle regular-text">
            Feel free to reach out to me if you have cool ideas or projects you
            want to develop!
          </span>
          <hr></hr>
        </div>
        <div className="contact-form">
          <form onSubmit={sendEmail}>
            <div className="mx-auto contact-form-item">
              <label htmlFor="name-input">
                Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="contact-input"
                id="name-input"
                placeholder="Your name"
                name="from_name"
                disabled={formDisabled}
                required
              />
            </div>
            <div className="mx-auto contact-form-item mt-3">
              <label htmlFor="email-input">
                Email <span className="text-danger">*</span>
              </label>
              <input
                type="email"
                className="contact-input"
                id="email-input"
                placeholder="Your email"
                name="from_email"
                disabled={formDisabled}
                required
              />
            </div>
            <div className="mx-auto contact-form-item mt-3">
              <label htmlFor="message-input">
                Subject <span className="text-danger">*</span>
              </label>
              <textarea
                className="contact-input"
                id="message-input"
                rows="3"
                placeholder="Your awesome idea or project!"
                disabled={formDisabled}
                required
                name="message"
              ></textarea>
            </div>
            <div>
              <span className="text-danger">* Required</span>
            </div>
            <div className="d-flex flex-row justify-content-between align-item-center">
              <div className="col-6 px-0">
                <input
                  className="custom-button"
                  type="submit"
                  value="Send Message"
                  disabled={formDisabled}
                />
              </div>
            </div>
          </form>
        </div>
        {showAlert && (
          <div className="custom-alert">
            {(successAlert && (
              <div className="alert alert-success h-100" role="alert">
                Thank you for reaching out! I will get back to you as soon as I can!
              </div>
            )) ||
              (!successAlert && (
                <div className="alert alert-danger h-100" role="alert">
                  An error has occured and your message was not received.{' '}
                  <br></br> Please try again later.
                </div>
              ))}
          </div>
        )}
      </div>
    </Layout>
  );
}
